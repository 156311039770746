import React, { Component } from "react"

/* Constants */
import { utils, env } from '../constants'

/* Components */
import { Picture } from "@alicorn/poker-ui"




/* Widget Status */
class Status extends Component {

    render = () => {

        const { status, data, balance, currency, isPurchase, makePurchase, maxPay } = this.props

        if (!status) {
            return <div />
        }

        if (status === "insurance") {

            const { playerGame, sixthGame, ante, close, makeInsurance } = this.props

            let multiplier = 0

            if (playerGame && parseInt(playerGame.level) >= 4) {
                multiplier = playerGame.multiplier
            }

            if (sixthGame && Array.isArray(sixthGame) && sixthGame.length > 0) {
                if (sixthGame[0].level >= 4) {
                    multiplier = sixthGame[0].multiplier
                    if (sixthGame.length > 1) {
                        multiplier = multiplier + sixthGame[1].multiplier
                    }
                }
            }

            if (parseInt(multiplier) >= 4) {

                let insurances = [
                    parseInt(multiplier) / 4 * parseInt(ante) * 2,
                    parseInt(multiplier) / 2 * parseInt(ante) * 2,
                    parseInt(multiplier) / 4 * 3 * parseInt(ante) * 2,
                    parseInt(multiplier) * parseInt(ante) * 2
                ]
                if ((parseInt(multiplier) * parseInt(ante) * 2) > parseInt(maxPay)) {
                    insurances = [
                        parseInt(maxPay) / 4,
                        parseInt(maxPay) / 2,
                        parseInt(maxPay) / 4 * 3,
                        parseInt(maxPay)
                    ]
                }


                return (
                    <div className={`status insurance-content pc`}>

                        <div className="insurance-container">

                            <div className="status-title insurance absolute">Страховка</div>

                            <div className="insurance-box">
                                {insurances.map((item, index) => {

                                    const chipsList = utils.getChips(currency)

                                    const disabled = ((balance - ante * 2) < parseInt(item))
                                    const style = disabled ? 'disabled' : ''

                                    let current = null
                                    chipsList.forEach((chip) => {
                                        if (parseInt(item) >= chip.value) {
                                            current = chip.image
                                        }
                                    })

                                    return (
                                        <div onClick={() => !disabled && makeInsurance(item)} className={`insurance-chip ${style}`} key={`${index}`}>
                                            <Picture src={current} alt="Chip" />
                                            <div className={`insurance-chip-value size_${utils.fontSize(item, 14)}`}>{utils.counter(item)}</div>
                                            <span>{(index + 1) * 25}%</span>
                                        </div>
                                    )
                                })}

                            </div>

                            <div className="insurance-chips-cancel" onClick={() => close()}>
                                <Picture src={`${env.mediapoint}/images/undo.png`} alt='Undo' />
                                <div className="insurance-chips-cancel-text">ОТМЕНА</div>
                            </div>

                        </div>

                    </div>
                )

            }

        }

        if (status === "result") {
            return (
                <div className="status result-box">

                    <div className="result-container">

                        {data.result === "lose" && data.reason === "inaction" &&
                            <div className="status-title status-lose">
                                <span className="text">Дилер выиграл</span>
                                <p className="text">Вы ничего не выбрали</p>
                            </div>
                        }

                        {data.result === "lose" && data.reason === "pass" &&
                            <div className="status-title status-lose">
                                <span className="text">Дилер выиграл</span>
                                <p className="text">Вы выбрали ПАС</p>
                            </div>
                        }

                        {data.result === "lose" && data.reason === "bet" &&
                            <div className="status-title status-lose">
                                <span className="text">Дилер выиграл</span>
                            </div>
                        }

                        {data.result === "draw" && data.reason === "bet" &&
                            <div className="status-title status-win">
                                <span className="text">Ничья</span>
                                <p className="text">Возврат ставки</p>
                                <span className="text">
                                    <Picture className="left" src={`${env.mediapoint}/images/winner/white-left.svg`} alt='left' />
                                    <b>{utils.convertor(data.sum, currency)}</b>
                                    <Picture className="right" src={`${env.mediapoint}/images/winner/white-right.svg`} alt='right' />
                                </span>
                            </div>
                        }

                        {data.result === "win" && data.reason === "bet" &&
                            <div className="status-title status-win">
                                <span className="text">Вы выиграли</span>
                                <span className="sum">
                                    <Picture className="left" src={`${env.mediapoint}/images/winner/money-left.svg`} alt='left' />
                                    <b>{utils.convertor(data.sum, currency)}</b>
                                    <Picture className="right" src={`${env.mediapoint}/images/winner/money-right.svg`} alt='right' />
                                </span>
                            </div>
                        }

                        {data.result === "win" && data.reason === "nogame" &&
                            <div className="status-title status-win">
                                <span className="text">Вы выиграли</span>
                                <span className="sum">
                                    <Picture className="left" src={`${env.mediapoint}/images/winner/money-left.svg`} alt='left' />
                                    <b>{utils.convertor(data.sum, currency)}</b>
                                    <Picture className="right" src={`${env.mediapoint}/images/winner/money-right.svg`} alt='left' />
                                </span>
                                <p className="text">У дилера нет игры</p>
                            </div>
                        }

                        {data.result === "win" && data.reason === "purchase-no-game" &&
                            <div className="status-title status-win">
                                <span className="text">Возврат ставки</span>
                                <span className="text">
                                    <Picture className="left" src={`${env.mediapoint}/images/winner/white-left.svg`} alt='left' />
                                    <b>{utils.convertor(data.sum, currency)}</b>
                                    <Picture className="right" src={`${env.mediapoint}/images/winner/white-right.svg`} alt='right' />
                                </span>
                                <p className="text">У дилера нет игры</p>
                            </div>
                        }

                    </div>

                </div>
            )
        }

        if (isPurchase) {
            return (
                <div className={`status purchase-box`}>

                    <div className="purchase-container">
                        <div className="status-title purchase absolute">Купить игру для дилера?</div>

                        <div className="status-buttons">
                            <div onClick={() => makePurchase("no")} className="status-button red">Нет</div>
                            <div onClick={() => makePurchase("yes")} className="status-button">Да</div>
                        </div>
                    </div>

                </div>
            )
        }

        return (
            <div className={`status`}>
            </div>
        )
    }

}

export default Status