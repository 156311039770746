import React, { Component } from "react"

/* Constants */
import { sound } from '../constants'



/* Component Timing */
class Timing extends Component {

    constructor() {
        super()

        this.state = {
            second: 0,
            command: ''
        }

        this._timing = null
    }


    start = (counter, command = '') => {

        this.setState({ second: counter, command }, () => {

            if (this._timing) {
                clearInterval(this._timing)
                this._timing = null
            }

            this._timing = setInterval(() => {

                const { second } = this.state
                const { isPaused, style } = this.props
                if (second <= 0) {
                    clearInterval(this._timing)
                    this._timing = null
                }
                else {
                    if (!isPaused) {
                        this.setState({ second: second - 1 })
                        if (second - 1 <= 5) {
                            if (style !== 'hidden') {
                                sound.play('tic', 0.5)
                            }
                        }
                    }
                }

            }, 1000)

        })

    }



    /* Draw Command */
    _command = () => {

        const { command } = this.state

        // if (command === "choise") {
        //     return <div className="timing-text">Делайте ставки</div>
        // }

        // if (command === "firstGame") {
        //     return <div className="timing-text">Выберите исход</div>
        // }

    }



    render = () => {

        const { second, command } = this.state

        const { style = '' } = this.props

        let zero = ''


        let color = second > 10 ? "green" : second > 5 ? "orange" : "red"

        if (second === 0) {
            zero = 'timing-zero'
            return null
        }

        if (command === "choise" && parseInt(second) > 20000) {
            zero = 'timing-zero'
        }

        if (command === "firstGame" && parseInt(second) > 20000) {
            zero = 'timing-zero'
        }

        if (command === "collect" && parseInt(second) > 5) {
            zero = 'timing-zero'
        }

        if (command === "collect" && parseInt(second) > 5) {
            zero = 'timing-zero'
        }

        // if (command === "noplayer") {
        //     return (
        //         <div className="timing">
        //             {this._command()}
        //         </div>
        //     )
        // }

        return (
            <div className={`timing ${zero} ${style}`}>
                {this._command()}
                <div className={`timing-box ${color}`}>{second}</div>
                <div className="timing-shadow" />
            </div>
        )
    }

}

export default Timing