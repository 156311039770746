import React, { Component, createRef } from 'react'

/* Socket IO */
import { io } from "socket.io-client"

/* JWT */
import { decodeToken } from 'react-jwt'

/* Constants */
import { images, env, sound, times } from './constants'
import { LOADING, ERROR, CHOICE, GAME, WAITING, DEALING } from './constants/status'

/* UUID */
import { v4 as uuidv4 } from 'uuid'

/* Alicorn Poker UI */
import { Error, Background, GameControl, Balance, Play, Pass, Open, Internet, Rates, MobileRate, Notification, FloatingMessages, Timer, Status, GameInfo } from '@alicorn/poker-ui'

/* Widgets */
import { Preloader, PlayerCards, DealerCards } from './widgets'

/* Rules */
import { list } from './constants/rules'

/* REST API */
import { history } from './api/History'

/* Game quit alicorn */
import { setIframeMessageSenderSettings, useSendInitIframeMessage } from '@alicorn/iframe-message-sender'

/* Components */
import { InactionTiming } from './components'

/* Widgets */
import { Bet } from './widgets'

/* Intenet speed check url */
const imgUrl = "https://testing-cb.makaobet.com/images/dealerinfo/close.png"

/* Fields */
const pathname = window.location.search
const token = pathname.length > 1 ? pathname.substring(1) : ""

/* FRAME SETTINGS */
setIframeMessageSenderSettings({ parentName: 'makao-front', sourceName: 'poker', isChild: true })


/* Entry Point */
class App extends Component {


    constructor() {
        super()

        const data = decodeToken(token)

        this.state = {

            /* General */
            cached: false,
            status: LOADING,
            balance: null,
            total: 0,
            currency: (token && data && data.currency) ? data.currency : 'USD',
            identifier: (token && data && data.identifier) ? data.identifier : '1',
            lastWin: 0,
            activeChip: 3,
            ante: 0,
            last: 0,
            total: 0,
            result: null,

            /* Player */
            playerCards: [],
            playerGame: null,

            /* Dealer */
            dealerInfo: null,
            dealerCards: [],
            dealerGame: null,

            /* Game Info */
            max: data && data.max ? data.max : 5000,
            min: data && data.min ? data.min : 50,
            maxPay: data && data.maxPay ? data.maxPay : 100000,

            times: {
                choise: times.CARD_HAND_UP_TIME,
                firstGame: times.FIRST_GAME_STAGE_TIME,
                secondGame: times.SECOND_GAME_STAGE_TIME,
            },

            stage: null,
            activeAction: false,
            isOpen: false,
            isBet: false,
            isPass: false,
            isDealerOpen: false,
            used: false,

            gameInfo: null,
            transactions: [],
            operations: [],
            histories: [],
            historyLoading: true,
            historyPage: 1,
            historyHasMore: true,

            /* Game Session */
            closed: false,
            newDevice: false,
            isDisconnected: false,
            isPaused: false,

            /* Other */
            hls: data && data.hls ? data.hls : null,
            chips: data && data.chips && Array.isArray(data.chips) ? data.chips : [],
            backColor: data && data.backColor ? data.backColor : "",
            gameImg: data && data.gameImg ? `${env.mediapoint}/images/table-images/` + data.gameImg : null,
        }

        this.notifyRef = React.createRef()

        /* Start connection */
        this.socket = io(env.endpoint, { auth: { token: `${token}`, reconnection: false, isPlayer: true } })

        /* Timing ref */
        this._balanceErrorTiming = null
        this._maxBetErrorTiming = null

        /* Backfround ref */
        this._background = createRef(null)
    }

    componentDidMount = () => {
        this.game()
        this.cache()
        this.loadHistory(1)
    }

    componentWillUnmount = () => {
        this.socket.disconnect()
        this.clear()
    }

    /* Load History */
    loadHistory = (page = 1) => {

        const { histories } = this.state

        if (page > 1) {
            this.setState({ historyPage: page })
        }

        history(token, page).then(response => {
            if (response.status === 200) {
                let list = response.data

                if (page > 1) {
                    list = [...histories, ...response.data]
                }

                if (response.data.length < 5) {
                    this.setState({ historyHasMore: false })
                }

                this.setState({ histories: list, historyLoading: false })
            }
        }).catch(() => {
            this.setState({ histories: [], historyLoading: false })
        })
    }

    /* Send Message */
    sendMessage = message => {
        this.socket.emit("messageFromPlayer", message.message)
        if (this._floatingMessages) {
            this._floatingMessages.sendMessage(message)
        }
    }

    game = () => {
        /* On error */
        this.socket.on("connect_error", () => {

            this.clear()

            const timer = setTimeout(() => {
                this.setState({ status: ERROR })
                clearTimeout(timer)
            }, 1000)

            this.clearTiming()

        })

        /*  */
        this.socket.on("disconnect", () => {
            this.setState({ isDisconnected: true })
            this.clear()

            this.clearTiming()
        })

        /* On connect */
        this.socket.on("connect", () => {
            const timer = setTimeout(() => {
                this.setState({ status: null })
                clearTimeout(timer)
                this.resetTimer()
            }, 2000)
        })

        /* On New Device */
        this.socket.on("newDeviceConnection", () => {
            this.setState({ newDevice: true })
            this.socket.disconnect()
            this.clear()

            this.clearTiming()
        })

        /* On start */
        this.socket.on("status", data => {
            const { status, ante } = this.state

            if (status !== data) {
                if (data === CHOICE && status !== CHOICE) {
                    this.loadHistory(1)
                    this.clear()
                }
                if (data === GAME) {
                    this.setState({ activeAction: null })
                }
                if (data !== CHOICE && !ante) {
                    this.setState({ status: WAITING })
                }
                else {
                    this.setState({ status: data })
                }
            }
        })


        /* Balance */
        this.socket.on("balance", data => {
            this.setState({ balance: data })
        })

        /* Get player ante */
        this.socket.on("playerAnte", data => {
            this.setState({ ante: data, last: data, total: data, lastWin: 0 })
        })

        this.socket.on("reconnection", data => {
            if (data) {

                this.reconnectionClear()

                if (data.reason && data.reason === "transaction") {
                    this.notify("transaction", 'show')
                }

                const status = data.status
                const ante = data.ante ? data.ante : 0
                const times = data.times
                const stage = data.stage ? data.stage : null

                const playerCards = data.playerCards
                const playerGame = data.playerGame

                const dealerCards = data.dealerCards
                const dealerGame = data.dealerGame

                const isPaused = data.isPaused

                setTimeout(() => {
                    this.setState({ status })
                    if (isPaused) {
                        this.notify("pause", "show")
                    }

                    if (data.autopass) {
                        this.notify("autopass", "show")
                        setTimeout(() => {
                            this.notify("autopass", "close")
                        }, 5000);
                    }

                    this.notify("pause", 'close')
                    this.notify("transaction", 'close')

                    if (ante) {
                        this.setState({
                            ante,
                            total: data.total,
                            times,
                            isPaused,

                            gameInfo: data.gameData,

                            playerGame,
                            dealerGame,

                            playerCards,
                            dealerCards,

                            isBet: data.bet,
                            isPass: data.pass,
                            isDealerOpen: data.isDealerOpen,

                            used: data.used,

                            activeAction: null,

                            result: data.result,

                            transactions: data.transactions

                        })
                    }

                    this.setState({ stage: data.stage, dealerInfo: data.dealerData })

                    setTimeout(() => {
                        if (this._timing && times && stage && parseInt(times[stage]) > 0) {
                            this._timing.start(times[stage], stage)
                        } else {
                            setTimeout(() => {
                                if (this._timing && times && stage && parseInt(times[stage]) > 0) {
                                    this._timing.start(times[stage], stage)
                                }
                            }, 1500)
                        }
                    }, 1000)
                }, 2000)
            }
        })

        /* Get pause command from dealer monitor */
        this.socket.on("pause", () => {
            this.setState({ isPaused: true })
            this.notify('pause', 'show')
        })

        /* Continue Event */
        this.socket.on("continue", () => {
            this.setState({ isPaused: false })
            this.notify('pause', 'close')
        })

        /* Get a game status after inaction  */
        this.socket.on("actions", data => {

            const { used, pass } = data

            this.setState({ used: used, isPass: pass ? true : false })

            if (pass) {
                this.setState({ status: DEALING })
                this.notify('autopass', 'show')
                sound.play('autopass')
                setTimeout(() => { this.notify('autopass', 'close') }, 5000)
            }
        })

        /* Get a dealer Info */
        this.socket.on("dealerData", data => {
            this.setState({ dealerInfo: data })
        })

        /* Get a dealer card */
        this.socket.on("dealer", data => {
            const { dealerCards } = this.state
            let cards = dealerCards
            const index = cards.findIndex(e => e.uid === data.uid)
            if (index === -1) {
                cards.unshift(data)
                this.setState({ dealerCards: cards })
            }
        })

        /* Get a dealer card */
        this.socket.on("dealerCards", data => {
            this.setState({ dealerCards: data })
        })

        this.socket.on("dealerGame", data => {
            this.setState({ dealerGame: data })
        })

        /* Get a Player card */
        this.socket.on("player", data => {
            const { playerCards } = this.state
            let cards = playerCards
            cards.unshift(data)
            this.setState({ playerCards: cards })
            sound.play('clickuibut', 0.2)
        })

        /* Get a player card */
        /*this.socket.on("playerCards", data => {
            this.setState({ playerCards: data })
            sound.play('clickuibut', 0.2)
        })*/

        this.socket.on("playerGame", data => {
            this.setState({ playerGame: data })
        })

        this.socket.on("isDealerOpen", () => {
            this.setState({ isDealerOpen: true })
        })

        /* Timer Stage */
        this.socket.on("stage", data => {
            const { type, times } = data

            this.setState({ stage: type })
            if (this._timing && times && parseInt(times[type]) > 0 && type) {
                this._timing.start(times[type], type)
            }

        })

        /* Result */
        this.socket.on("result", result => {
            this.setState({ result })
            if (result && result.result === "win") {
                sound.play('win', 0.5)
                this.setState({ lastWin: result.sum })
            }
        })

        /* Message */
        this.socket.on("message", data => {
            if (this._floatingMessages) {
                this._floatingMessages.sendMessage(data)
            }
            if (this._background && this._background.current) {
                this._background.current.addMessage(data)
            }
        })

        /* Get Game Info */
        this.socket.on("gameInfo", data => {
            this.setState({ gameInfo: data })
        })

        /* Get transactions list */
        this.socket.on("transaction", data => {
            if (data.id) {
                const { transactions, operations } = this.state
                let list = transactions
                let operationsList = operations
                list.push(data)
                operationsList.push(data)
                this.setState({ transactions: list, operations: operationsList })

                setTimeout(() => {
                    let operationsList = this.state.operations
                    operationsList = operationsList.filter(item => item.id !== data.id)
                    this.setState({ operations: operationsList })
                }, 3500)

            }
        })

        this.socket.on("editTransaction", data => {
            const { transactions } = this.state
            let list = transactions
            const index = list.findIndex(e => e.number === data.number)
            if (index > -1) {
                list[index].status = data.status
                this.setState({ transactions: list })
            }
        })


        this.socket.on("forceEnd", () => {
            this.forceEnd()
        })

    }

    /* Clear timing to remove sound */
    clearTiming = () => {
        if (this._timing) {
            this._timing.clear()
        }
        setTimeout(() => {
            if (this._timing) {
                this._timing.clear()
            }
        }, 1500)
    }

    /* Show and close notifications */
    notify = (type, action, data = null) => {

        const notifyData = { type, action, data }

        if (this._notify) {
            this.notifyAction(notifyData)
        }
        else {
            setTimeout(() => {
                if (this._notify) {
                    this.notifyAction(notifyData)
                }
            }, 2000)
        }

    }

    /* Do notify actions after notify component check */
    notifyAction = (notifyData) => {

        const { type, action, data = null } = notifyData
        if (action === "show") {
            this._notify.show(type)
        }
        else if (action === 'close') {
            this._notify.close(type)
        }
        else if (action === 'refund') {
            if (data.total) {
                const title = data.type === 'refund' ? 'Возврат средств' : "Пополнение суммы"
                const uid = uuidv4()
                this._notify.refund({ title, total: data.total, uid })
            }
        }

    }

    /* Check internet speed */
    checkInternetSpeed = (speed) => {
        if (parseInt(speed) < 5) {
            this.notify('internet', 'show')
        }
        else {
            this.notify('internet', 'close')
        }
    }

    /* Cache images */
    cache = async () => {

        const promises = images.data.map(uri => {

            return new Promise(function (resolve, reject) {
                const img = new Image()
                img.src = uri
                img.onload = resolve()
                img.onerror = reject()
            })

        })

        await Promise.all(promises)

        this.setState({ cached: true })
    }

    /* Reser top timing */
    resetTimer = () => {
        if (this._inaction) {
            this._inaction.reset()
        }
    }

    /* Disconnect user when inaction timer equal to 0 */
    setInactionState = () => {
        this.setState({ closed: true })
        this.socket.disconnect()
        this.clear()
    }

    clear = () => {
        this.setState({
            ante: 0,
            playerCards: [],
            playerGame: null,
            dealerCards: [],
            dealerGame: null,
            additionalCards: [],

            result: null,

            times: {
                choise: times.CARD_HAND_UP_TIME,
                firstGame: times.FIRST_GAME_STAGE_TIME,
                secondGame: times.SECOND_GAME_STAGE_TIME
            },

            stage: null,
            activeAction: null,
            isOpen: false,
            isBet: false,
            isPass: false,
            isDealerOpen: false,

            used: false,

            gameInfo: null,
            transactions: [],
            operations: [],
        })
    }


    /* Reconnection Clear */
    reconnectionClear = () => {
        this.setState({
            ante: 0,
            total: 0,
            times: {
                choise: times.CARD_HAND_UP_TIME,
                firstGame: times.FIRST_GAME_STAGE_TIME,
                secondGame: times.SECOND_GAME_STAGE_TIME
            },
            isPaused: false,

            gameInfo: null,

            playerGame: null,
            dealerGame: null,

            playerCards: [],
            dealerCards: [],

            isBet: false,
            isPass: false,
            isDealerOpen: false,
            used: false,

            activeAction: null,

            result: null,

            transactions: [],
            operations: [],
        })
    }


    /* Force End Action */
    forceEnd = () => {

        this.notify("pause", 'close')

        const timer = setTimeout(() => {
            this.notify('forceEnd', 'show')

            const close = setTimeout(() => {
                this.notify('forceEnd', 'close')
                clearTimeout(close)
            }, 4000)

            clearTimeout(timer)
        }, 2000)

        this.setState({
            status: CHOICE,
            isPaused: false,
            ante: 0,
            playerCards: [],
            playerGame: null,
            dealerCards: [],
            dealerGame: null,
            result: null,
            times: {
                choise: times.CARD_HAND_UP_TIME,
                firstGame: times.FIRST_GAME_STAGE_TIME,
                secondGame: times.SECOND_GAME_STAGE_TIME
            },
            stage: null,
            activeAction: null,
            isOpen: false,
            isBet: false,
            isPass: false,
            isDealerOpen: false,
            used: false,
            gameInfo: null,
            transactions: [],
            operations: [],
        })
    }



    setAnte = (data) => {
        this.setState({ ante: data, last: data, total: data, lastWin: 0 })
        this.socket.emit("ante", data)
        sound.play('sound')
        this.resetTimer()
    }


    /* Toggle Balance Error */
    toggleBalanceError = () => {

        if (!this._balanceErrorTiming) {
            this.notify('balanceError', 'show')
        }

        if (this._balanceErrorTiming) {
            clearTimeout(this._balanceErrorTiming)
            this._balanceErrorTiming = null
        }

        this._balanceErrorTiming = setTimeout(() => {
            this.notify('balanceError', 'close')
            clearTimeout(this._balanceErrorTiming)
            this._balanceErrorTiming = null
        }, 2000)

    }

    /* Toggle Balance Error */
    toggleMaxBetError = () => {

        if (!this._maxBetErrorTiming) {
            this.notify('maxBetError', 'show')
        }

        if (this._maxBetErrorTiming) {
            clearTimeout(this._maxBetErrorTiming)
            this._maxBetErrorTiming = null
        }

        this._maxBetErrorTiming = setTimeout(() => {
            this.notify('maxBetError', 'close')
            clearTimeout(this._maxBetErrorTiming)
            this._maxBetErrorTiming = null
        }, 2000)

    }

    action = (type) => {

        const { total, ante, used } = this.state

        let tempTotal = total


        if (type === "bet") {
            this.socket.emit("bet")
            sound.play('sound')
            this.setState({ isBet: true })

            if (!used && this._playerCards) {
                this._playerCards.clearCards()
            }

            tempTotal += ante * 2
        }
        if (type === "pass") {
            this.socket.emit("pass")

            if (!used && this._playerCards) {
                this._playerCards.clearCards()
            }

            this.setState({ isPass: true })
        }
        if (type === "open") {
            this.socket.emit("open")
            sound.play('selectchip')
            this.openChip()
            tempTotal += ante / 2
        }

        this.setState({ used: true, activeAction: type, status: DEALING, total: tempTotal })
        this.resetTimer()

    }

    /* Bottom Bet action */
    bottomBet = () => {

        const { status, result, isPass } = this.state

        if (status === GAME && !result && !isPass) {
            this.action('bet')
        }

        this.resetTimer()
    }

    /* Open Chip Animation */
    openChip = () => {
        this.setState({ isOpen: true })
        setTimeout(() => { this.setState({ isOpen: false }) }, 3000)
    }

    /* Draw Buttons */
    _buttons = () => {

        const { status, used, isBet, isPass, activeAction, result } = this.state

        const isFreezed = isBet || isPass
        const style = result ? 'hidden' : ''
        const visible = isFreezed || (status === GAME && !activeAction)

        if (visible) {
            return (
                <div className={`buttons ${style}`}>
                    <div className="buttons-row">
                        {!used ? <Open onClick={() => this.action("open")} /> : null}
                        {!isPass ? <Play selected={isBet} onClick={() => this.action("bet")} /> : null}
                        {!isBet ? <Pass selected={isPass} onClick={() => this.action("pass")} /> : null}
                    </div>
                </div>
            )
        }
    }


    /* Draw Result */
    _result = () => {

        const { result, currency } = this.state

        if (result) {
            return <Status status="result" data={result} currency={currency} isPurchase={false} />
        }
    }

    box = () => {

        /* Fields */
        const { status, currency, balance, ante, last, used, activeChip, dealerCards, dealerGame, playerCards, playerGame, result, isBet, isPass, isDealerOpen, isOpen, times, stage, max, min, chips } = this.state
        const gameplay = status === GAME || status === DEALING

        const boxStyle = isPass ? "isPass" : ""
        const lose = result && result.result === 'lose'

        /* Shadow active animations for BET and ANTE */
        const anteAnimatedStyle = (stage === "choise" && !ante) ? "animated" : ""
        const betAnimatedStyle = ((stage === "firstGame" && !used) || (stage === "secondGame" && !isPass && !isBet)) ? "animated" : ""

        /* GAME */
        if (gameplay) {
            return (
                <div className={`play one-box ${boxStyle}`}>

                    {this._result()}

                    <div className="card-box">

                        <DealerCards
                            cards={dealerCards}
                            game={dealerGame}
                            isDealerOpen={isDealerOpen}
                        />

                        <div className="card-box-bottom">

                            <MobileRate currency={currency} gameScreen={true} ante={ante} result={result} isBet={isBet} isPass={isPass} isOpen={isOpen} add={() => { }} bet={() => this.bottomBet()} boxStyle={boxStyle} chips={chips} lose={lose} half={true} />

                            <div className="player-game">

                                <PlayerCards
                                    disabled={true}
                                    ref={ref => this._playerCards = ref}
                                    cards={playerCards}
                                    game={playerGame}
                                />


                                <div className="rmp--buttons">
                                    {this._buttons()}
                                </div>

                            </div>
                        </div>
                    </div>

                    <Rates
                        currency={currency}
                        gameScreen={true}
                        ante={ante}
                        result={result}
                        isBet={isBet}
                        isPass={isPass}
                        isOpen={isOpen}
                        add={() => { }}
                        bet={() => this.bottomBet()}
                        boxStyle={boxStyle}
                        betAnimatedStyle={betAnimatedStyle}
                        chips={chips}
                        lose={lose}
                        half={true}
                    />
                </div>
            )
        }

        if (status === CHOICE) {
            return (
                <Bet
                    ante={ante}
                    last={last}
                    active={activeChip}
                    setActiveChip={data => this.setState({ activeChip: data })}
                    setAnte={data => this.setAnte(data)}
                    balance={balance}
                    currency={currency}
                    toggleBalanceError={() => this.toggleBalanceError()}
                    toggleMaxBetError={() => this.toggleMaxBetError()}
                    times={times}
                    boxStyle={boxStyle}
                    anteAnimatedStyle={anteAnimatedStyle}
                    max={max}
                    min={min}
                    chips={chips}
                    lose={lose}
                />
            )
        }

        return (
            <div className={`play one-box ${'isPass'}`}>
                <Rates disabled currency={currency} ante={ante} result={result} isBet={isBet} isOpen={isOpen} add={() => { }} bet={() => this.bottomBet()} boxStyle={"isPass"} chips={chips} />
            </div>
        )

    }

    render = () => {

        const { status, cached, dealerInfo, currency, total, balance, lastWin, ante, used, transactions, gameInfo, operations, histories, historyPage, historyHasMore, closed, newDevice, max, min, maxPay, isDisconnected, isPaused, identifier, isBet, isPass, stage, hls, backColor, gameImg } = this.state

        const isFreezed = isBet || isPass

        const nextGame = status === WAITING

        const style = (nextGame || status === ERROR || status === LOADING || isFreezed || newDevice || closed || isDisconnected || (stage === "firstGame" && used)) ? "hidden" : ""

        let content = (
            <Background gameType="rmp" ref={this._background} url={hls} currency={currency} maxPay={maxPay} status={status} dealer={dealerInfo} sendMessage={message => this.sendMessage(message)} info={gameInfo} transactions={transactions} histories={histories} loadMore={() => this.loadHistory(historyPage + 1)} historyHasMore={historyHasMore} identifier={identifier} rules={list} videoBackImg={gameImg} textileColor={backColor}>

                <Timer key="timing" ref={ref => this._timing = ref} style={style} isPaused={isPaused} ante={ante} />
                <InactionTiming ref={ref => this._inaction = ref} status={status} isPaused={isPaused} setInactionState={() => this.setInactionState()} />
                <HookWrapper />
                <GameInfo currency={currency} info={gameInfo} operations={operations} />
                <GameControl title={`Open Poker`} maxPay={maxPay} max={max} min={min} currency={currency} info={gameInfo} data={dealerInfo} onQuitGame={() => this.quitGame()} />
                <Balance balance={balance} total={total} lastWin={lastWin} currency={currency} />
                <FloatingMessages ref={ref => this._floatingMessages = ref} />

                {this.box()}

                <Notification nextGame={nextGame} ref={ref => this._notify = ref} currency={currency} max={max} />

                <Internet url={imgUrl} setInternetSpeed={data => this.checkInternetSpeed(data)} />

            </Background>
        )

        if (status === ERROR || newDevice || closed || isDisconnected) {
            content = <Error newDevice={newDevice} closed={closed} isDisconnected={isDisconnected} />
        }

        if (status === LOADING || !cached) {
            content = <Preloader url={gameImg} />
        }

        return content
    }

}

export default App

function HookWrapper() {
    useSendInitIframeMessage()
    return null
}